/* top title bar */
.title-wrap {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;

  .title-text {
    color: #1a6985;
    div {
      font-size: 30px;
    }
  }

  .title-settings {
    color: #1a6985;
    padding-top: 4px;
    padding-right: 4px;
    span svg {
      background-color: rgba(0, 0, 0, 0);
      font-size: 40px;
      padding: 3px;
      border-radius: 50px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
        /* color: #88b; */
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.03);
        /* color: #66b; */
      }
    }
    .env {
      background-color: rgba(0, 155, 0, 0.75);
      padding: 3px 6px;
      color: #fff;
      border-radius: 4px;
    }
  }
}
