.hover {
  background-color: aliceblue;
}

.sub-form .form-check-label {
  min-width: 50px;
  padding-left: 2px;
}

.form-style {
  padding: 10px 5px;
  input.form-control {
    padding: 5px 15px !important;
    // border-radius: 50px !important;
    border-radius: 8px !important;
    border-color: #e5e5ff;
    color: #00005c;
    &:focus {
      border-color: #e5e5ffbf;
      box-shadow: 0 0 0 0.25rem rgb(229 229 255 / 75%);
      &::selection {
        background-color: #e5e5ff;
      }
    }
    &:disabled {
      background-color: #f4f6f7;
      border-color: #e9ecef !important;
      color: #888 !important;
    }
  }
  .form-label {
    // font-weight: 500;
    color: #666;
    margin-bottom: 4px;
  }
}
.error {
  // background-color: rgba(220 53 69 / 10%);
  label.form-label {
    color: #d82537;
  }
  .clearfix > .float-start {
    color: #d82537 !important;
  }
  input.form-control {
    border-color: #ffdede !important;
    &:focus {
      border-color: #ffdede !important;
      box-shadow: 0 0 0 0.25rem rgb(255 196 196 / 25%);
    }
  }
}
.warn {
  // background-color: rgba(255 193 7 / 10%);
  label.form-label {
    color: #f2b600;
  }
  .clearfix > .float-start {
    color: #f2b600 !important;
  }
  input.form-control {
    border-color: rgba(255 193 7 / 25%) !important;
    &:focus {
      border-color: #ffffe1 !important;
      box-shadow: 0 0 0 0.25rem rgb(255 193 7 / 10%);
    }
  }
}
