.milestone-states-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.milestone-states-container .badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
}

.milestone-name-column {
  width: 520px;
  font-weight: bold;
}

.time-column {
  width: 100px;
}

.remaining-column {
  width: 150px;
}

.labels-column {
  width: 50px;
  text-align: center;
}

.badge-label {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: 14px;
  padding: 0.5rem 1rem;
  background-color: #6c757d;
  color: #fff;
  border-radius: 0.25rem;
}


.milestone-table {
  --bs-table-hover-bg: #fafafa;
  font-size: 14px;
  table-layout: fixed;
  width: 100%;
}

.milestone-table th,
.milestone-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default; 
}


.milestone-table th {
  background-color: #fafafa;
  &:hover {
    background-color: #f5f5f5;
  }
}

.me-2.activation {
  background-color: #dee8e4 !important;
  color: #6a8779;
  border-color: #c6d8d1;
}

.me-2.snow {
  background-color: #cfdfe1 !important;
  color: #3f9aa9;
  border-color: #b7d1d4;
}

.me-2.gainsight {
  background-color: #d3d1d9 !important;
  color: #7a6889;
  border-color: #beb7c3;
}

.me-2.mavenlink {
  background-color: #e6e0d8 !important;
  color: #a88c61;
  border-color: #cec6bc;
}

.category-activation .milestone-name {
  color: #6a8779;
}

.category-snow .milestone-name {
  color: #3f9aa9;
}

.category-mavenlink .milestone-name {
  color: #a88c61;
}

.category-gainsight .milestone-name {
  color: #7a6889;
}
