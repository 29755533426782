.sub-table {
  --bs-table-hover-bg: #fafafa;
  font-size: 15px;
  table-layout: fixed;
  width: 100%;
}

.sub-table tr {
  height: 1px;
}

.sub-table th,
.sub-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  height: inherit;
  overflow: visible;
}

.sub-table th {
  background-color: #fafafa;
  &:hover {
    background-color: #f5f5f5;
  }
  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
}

.sub-table th:nth-child(1),
.sub-table td:nth-child(1) {
  width: 12%;
}

.sub-table th:nth-child(2),
.sub-table td:nth-child(2) {
  width: 13%;
}

.sub-table th:nth-child(3),
.sub-table td:nth-child(3) {
  width: 13%;
}

.sub-table th:nth-child(4),
.sub-table td:nth-child(4) {
  width: 16%;
}

.sub-table th:nth-child(5),
.sub-table td:nth-child(5) {
  width: 15%;
}

.sub-table th:nth-child(6),
.sub-table td:nth-child(6) {
  width: 10%;
}

.sub-table th:nth-child(7),
.sub-table td:nth-child(7) {
  width: 10%;
}

.sub-table th:nth-child(8),
.sub-table td:nth-child(8) {
  width: 12%;
}
.sub-table td:nth-child(9) {
  width: 3%;
  padding: 0px;
  background-color: #fff;
  &:hover {
    background-color: #fff;
  }
  cursor: pointer;
  text-align: center;
}

.dt-offer-tooltip {
  --bs-tooltip-bg: #f9f2fc;
  --bs-tooltip-max-width: fit-content;
  --bs-tooltip-opacity: 1;
}


.tooltip-bundle {
  padding: 2px 4px;
  margin: 1px;
  color: #270c33;
  &:not(:last-child) {
    border-bottom: 1px dotted #c682e2;
  }
}

.tooltip-offer {
  padding: 2px 4px;
  margin: 2px;
  border: 1px solid #e2f2ff;
  font-size: 14px;
  background-color: #f6fbff;
  cursor: default;
}

.onboarding-status-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.onboarding-status-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 4px);
  box-sizing: border-box;
  margin: 2px 0;
}

.tooltip-app {
  padding: 2px 4px;
  margin: 2px;
  border: 1px solid #daf1ed;
  font-size: 14px;
  background-color: #e9f7f440;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: default;
  .app-status-wrap {
    padding: 0px 2px 0px 2px;
    .app-status-icon {
      font-size: 16px;
      padding-top: 3px;
    }
  }
  .app-desc-wrap {
    padding: 0px 2px;
    width: 90%;
    .app-title {
      font-size: 14px;
      // width: 99%;
    }
    .app-instance {
      font-size: 11px;
      // width: 99%;
    }
  }
}

.tooltip-app.SUCCESS {
  background-color: #f1fcf7;
  border-color: #e0f9ee;
  color: #198754;
}
.tooltip-app.IN_PROGRESS {
  background-color: #fff3ea;
  border-color: #ffe9d6;
  color: #873e01;
}
.tooltip-app.FAIL {
  background-color: #fcedee;
  border-color: #f9dcdf;
  color: #74141d;
}

.tooltip-app.Open {
  background-color: #e2e8e9;
  border-color: #d7dadd;
  color: #76898b;
}

.tooltip-app.Completed {
  background-color: #dbe5e1;
  border-color: #c6d8d1;
  color: #6a8779;
}

.tooltip-app.Activation {
  background-color: #dee8e4;
  border-color: #c6d8d1;
  color: #6a8779;
}

.tooltip-app.Snow {
  background-color: #cfdfe1;
  border-color: #b7d1d4;
  color: #3f9aa9;
}
.tooltip-app.Gainsight {
  background-color: #d3d1d9;
  border-color: #beb7c3;
  color: #7a6889;
}

.tooltip-app.Mavenlink {
  background-color: #e6e0d8;
  border-color: #cec6bc;
  color: #a88c61;
}

.tooltip-app.Other {
  background-color: #cdb2ad;
  border-color: #b49288;
  color: #827a77;
}

.acc-num {
  font-size: 12px;
}

.sub-id-note {
  font-size: 18px;
  color: rgb(32, 131, 166);
  cursor: pointer;
  margin-top: -2px;
}
.sub-id-note-text {
  margin-left: 2px;
  cursor: pointer;
}

.sub-note-modal {
  .modal-body {
    padding: 5px;
    .container {
      padding: 4px;
      .row {
        margin: 8px 0px;
        .sub-note-col {
          padding: 4px;
          .note-info {
            cursor: default;
            font-size: 13px;
            color: #999;
            .note-info-dot {
              margin: 0px 0px;
            }
          }
        }
      }
    }
  }
}

.order-status {
  font-size: 12px;
  padding: 3px 6px;
  background-color: #fcedee;
  color: #74141d;
  border-radius: 6px;
  font-weight: normal;
  margin: 5px 0px 0px 0px;
  border: 1px solid #f9dcdf;
}

.order-status.new {
  background-color: #f1fcf7;
  border-color: #e0f9ee;
  color: #198754;
}

.order-status.change {
  background-color: #fff3ea;
  border-color: #ffe9d6;
  color: #873e01;
}

.order-status.trial {
  background-color: #e1ecf4;
  border-color: #d0e3ea;
  color: #1b76a3;
}

.order-status.trialExtension {
  background-color: #e3ddf1;
  border-color: #e8e4f2;
  color: #7651c5;
}

.order-status.trialToPaid {
  background-color: #f8e6f5;
  border-color: #f6e1f4;
  color: #b039a3;
}

.order-status.commercial {
  background-color: #edd4f7;
  border-color: #ead4f3;
  color: #9831c0;
}

.order-status.internal {
  background-color: #eef3d0;
  border-color: #e7f0b5;
  color: #7a8a0e;
}

.order-status.active {
  background-color: #d3f5c6;
  border-color: #cdeebf;
  color: #388719;
}

.order-status.cancel {
  background-color: #f8d6da;
  border-color: #fcd4d9;
  color: #c43445;
}

.order-status.suspend {
  background-color: #f8f2be;
  border-color: #f2eeab;
  color: #908722;
}

.order-status.mavenlink {
  background-color: #efece7;
  border-color: #ddd8d1;
  color: #9a7947;
}

.filter-multiselect {
  .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 6px;
    min-height: 22px;
    padding: 2px 5px 2px 5px;
    position: relative;
    background: #fff;
    input {
      margin-top: 0px;
      font-size: 14px;
    }
    .chip {
      border-radius: 10px;
      font-size: 12px;
      line-height: 18px;
      padding: 2px 3px 2px 6px;
      margin: 4px 4px 4px 0px;
      background-color: #6c757d;
      color: #f1f1f1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      width: auto;
    }
  }
  .optionListContainer {
    ul.optionContainer {
      li.groupHeading {
        background-color: #edf6ff;
        color: #003c77;
        font-weight: 500;
        &:hover {
          background-color: #edf6ff;
        }
      }
      li {
        padding: 4px 6px;
        color: #6c757d;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        input.checkbox {
          accent-color: #6c757d;
          &:checked {
            accent-color: #6c757d;
          }
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.filter-section-title {
  padding: 5px 0px 5px 0px;
  span {
    font-size: 18px;
    font-weight: 500;
  }
  .filter-icon {
    font-size: 24px;
  }
  .toggle {
    color: #00f;
    cursor: pointer;
    text-decoration: underline;
  }
}

.filter-section {
  background-color: #fafafa;
  border-radius: 0px 6px 6px 6px;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 5px 0px;
  .form-label {
    font-size: 14px;
    margin-bottom: 2px;
  }
}

.pagesize-dropdown {
  .dropdown-menu {
    padding: 0px;
    border-radius: 4px;
    .dropdown-item {
      font-size: 14px;
      color: #333;
      &:hover {
        background-color: #f5f5f5;
      }
    }
    .dropdown-item.active {
      background-color: #ddd;
      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.download-sub-btn-icon {
  font-size: 20px;
  margin-top: -4px;
}

.create-sub-btn {
  padding: 5px 10px;
  background-color: #1a6985;
  cursor: pointer;
  color: #fff;
  border-radius: 6px;
  .create-sub-btn-icon {
    font-size: 18px;
    color: #fff;
    padding-right: 3px;
    margin-top: -2px;
  }
  .text {
    font-size: 16px;
  }
  &:hover {
    background-color: #2083a6;
  }
  &:active {
    background-color: #144f64;
  }
}

.internal-app-checkbox {
  cursor: pointer;
  .form-check-input {
    border-color: #6c757d;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(35, 37, 39, 0.25);
    }
  }
  .form-check-input:checked {
    background-color: #6c757d;
    border-color: #6c757d;
    &:hover {
      background-color: #5c636a;
      border-color: #5c636a;
    }
    &:focus {
      background-color: #565e64;
      border-color: #565e64;
      box-shadow: 0 0 0 0.25rem rgba(35, 37, 39, 0.25);
    }
  }
}
.snote-filter-toggle{
  height:32px;
}
.snote-filter-toggle::after {
  content: none !important;
}

