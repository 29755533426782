@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    50% { transform: translateX(4px); }
    75% { transform: translateX(-4px); }
    100% { transform: translateX(0); }
  }
  
  .shake-animation {
    animation: shake 0.5s ease-in-out;
  }
  .table-responsive {
    margin-bottom: 1rem;
    font-size: 8px;
  }
  
  .table-fit {
    min-width: 100%;
    width: auto;
  }
  .compare-table-cell {
   display: flex;
   overflow-x:hidden;
   font-size: 1rem;
  }
  .flow-graph {
    display: flex;
    align-items: center;
    margin: 5px;
    flex-direction: row;
  }
  
  .radio-container {
    display: flex;
    align-items: center;
  }
  
  .arrow {
    width: 50px;
    height: 2px;
    background-color: black;
    position: relative;
  }
  
  .arrow::after {
    content: '';
    position: absolute;
    top: -5px;
    right: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }
  

/* For Bootstrap v5 */
.prev-btn .form-check-input:checked  {
  background-color: rgb(161, 235, 177) !important;
  border-color:  rgb(161, 235, 177) !important;
}

.next-btn .form-check-input:checked {
  background-color: rgb(111, 118, 189) !important;
  border-color: rgb(111, 118, 189) !important;
}
