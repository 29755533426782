.loading {
  opacity: 0.5;
  pointer-events: none;
}

.cl-acc-status {
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #ccc;
  .acc-status {
    padding: 3px 4px !important;
    border-radius: 8px 0px 0px 8px;
  }
  .acc-name {
    padding: 3px 6px !important;
    cursor: default;
  }
  .acc-vendor {
    padding: 3px 6px 3px 2px !important;
    border-radius: 0px 8px 8px 0px;
  }
}

.cl-acc-status.warn {
  background-color: #fff3ea;
  border-color: #ffe4d1;
  color: #873e01;
  .acc-status {
    border-color: #ffe4d1;
  }
  .acc-name {
    border-color: #ffe4d1;
    border-left: 1px solid #ffe4d180 !important;
    border-right: 1px solid #ffe4d180 !important;
  }
  .acc-vendor {
    border-color: #ffe4d1;
  }
}

.cl-acc-status.success {
  background-color: #f1fcf7;
  border-color: #c7f3df;
  color: #198754;
  .acc-status {
    border-color: #c7f3df;
  }
  .acc-name {
    border-color: #c7f3df;
    border-left: 1px solid #c7f3df80 !important;
    border-right: 1px solid #c7f3df80 !important;
  }
  .acc-vendor {
    border-color: #c7f3df;
  }
}

.cl-acc-status.error {
  background-color: #ed000026;
  border-color: #ed000026;
  color: #8b0000;
  .acc-status {
    border-color: #ed000026;
  }
  .acc-name {
    border-color: #ed000026;
    border-left: 1px solid #ed00000d;
    border-right: 1px solid #ed00000d;
  }
  .acc-vendor {
    border-color: #ed000026;
  }
}

.filter-section-title {
  padding: 5px 0px 5px 0px;
  span {
    font-size: 18px;
    font-weight: 500;
  }
  .filter-icon {
    font-size: 24px;
  }
  .toggle {
    color: #00f;
    cursor: pointer;
    text-decoration: underline;
  }
}

.filter-section {
  background-color: #fafafa;
  border-radius: 0px 6px 6px 6px;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 5px 0px;
  .form-label {
    font-size: 14px;
    margin-bottom: 2px;
  }
}

.filter-multiselect {
  .searchWrapper {
    border: 1px solid #ccc;
    border-radius: 6px;
    min-height: 22px;
    padding: 2px 5px 2px 5px;
    position: relative;
    background: #fff;
    input {
      margin-top: 0px;
      font-size: 14px;
    }
    .chip {
      border-radius: 10px;
      font-size: 12px;
      line-height: 18px;
      padding: 2px 3px 2px 6px;
      margin: 4px 4px 4px 0px;
      background-color: #6c757d;
      color: #f1f1f1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      width: auto;
    }
  }
  .optionListContainer {
    ul.optionContainer {
      li.groupHeading {
        background-color: #edf6ff;
        color: #003c77;
        font-weight: 500;
        &:hover {
          background-color: #edf6ff;
        }
      }
      li {
        padding: 4px 6px;
        color: #6c757d;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        input.checkbox {
          accent-color: #6c757d;
          &:checked {
            accent-color: #6c757d;
          }
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

.env-table {
  --bs-table-hover-bg: #fafafa;
  font-size: 15px;
  table-layout: fixed;
  width: 100%;
}

.env-table tr {
  height: 1px;
}

.env-table th,
.env-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  height: inherit;
  overflow: visible;
}

.env-table th {
  background-color: #fafafa;
  &:hover {
    background-color: #f5f5f5;
  }
  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
}

.search-field {
  border-radius: 8px 0px 0px 8px;
  width: 16rem;
  &:focus {
    border-color: #d1d6dc;
    box-shadow: 0 0 0 0.25rem rgb(220 224 229 / 50%);
  }
}

.create-env {
  background-color: rgb(92, 99, 106);
}

.search-reset {
  border-radius: 0px 8px 8px 0px;
  svg {
    path {
      stroke: #fff !important;
    }
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.searchBox {
  width: 75%;
}

.expense-type {
  padding: 0px 4px 1px 4px;
  font-weight: 400;
  background-color: rgb(51 51 51 / 55%);
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
}

.admin-mode-check {
  background-color: #f5f5f5;
  padding: 2px 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  label {
    cursor: pointer !important;
  }
}

.etag {
  background-color: #f5f5f5;
  padding: 2px 6px;
  color: #666;
  margin: 0px 4px 6px 0px;
  font-size: 14px;
  border-radius: 6px;
  span.k {
    padding: 0px 5px;
    border-right: 1px solid #666;
    font-weight: 300;
  }
  span.v {
    padding: 0px 5px;
    font-weight: 500;
  }
}

.etag.s {
  background-color: #f5f5f5;
  border: 1px solid #e9e9e9;
  color: #666;
  span.k {
    border-right-color: #e5e5e5;
  }
}
.etag.u {
  background-color: #eefcff;
  border: 1px solid #b3f3ff;
  color: #00758c;
  span.k {
    border-right-color: #b3f3ff;
  }
}

.tag-edit {
  margin: -8px 0px 0px 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.75;
  }
}

.tags-m-t-head {
  text-align: center;
  background-color: #fafafa;
  padding: 6px 0px 6px 0px;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.tags-m-t-head.u {
  background-color: #eefcff;
  border-color: #c7f5ff;
  color: #006378;
}

.tags-m-rem-icon {
  font-size: 18px;
  cursor: pointer;
  color: #e33545;
  margin-top: -4px;
  &:hover {
    color: rgba(227, 53, 69, 0.75);
  }
  &:active {
    color: rgba(227, 53, 69, 1);
  }
}

.euser {
  background-color: #f5f5f5;
  padding: 2px 6px;
  color: #666;
  margin: 0px 4px 6px 0px;
  border-radius: 6px;
  border: 1px solid #ddd;
  .e {
    font-weight: 400;
  }
  .o {
    font-size: 11px;
  }
}

.add-user {
  font-size: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.75;
  }
}

.cef-switch {
  .form-check-input {
    width: 2.5em;
    height: 1.25em;
    border-color: #c2c2c2;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23d6d6d6%27/></svg>");
    &:focus {
      border-color: #c9cccf;
      box-shadow: 0 0 0 0.25rem rgba(133, 133, 133, 0.25);
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23d6d6d6%27/></svg>");
    }
    &:checked {
      background-color: #858585;
      border-color: #858585;
      background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23ffffff%27/></svg>");
    }
  }
}

.edit-icon,
.remove-icon {
  cursor: pointer;
  color: #333;
  font-size: 20px;
  &:hover {
    color: #999;
  }
  &:focus {
    color: #666;
  }
}

.pbe {
  padding: 2px 8px;
  background-color: #ecffec;
  border: 1px solid #c4ffc4;
  border-radius: 6px;
  color: #007600;
}

.pbc {
  padding: 2px 8px;
  background-color: #fbf3ef;
  border: 1px solid #f3dbd0;
  color: #cd6532;
  border-radius: 6px;
}

.pbo {
  padding: 2px 8px;
  background-color: #fafafa;
  border: 1px solid #eee;
  color: #333;
  border-radius: 6px;
}