.table-row-dropdown {
  .btn {
    padding: 0px 7px 2px 7px;
    background-color: #fff !important;
    border-color: #ccc !important;
    color: #000;
    font-size: 16px;
    &:hover,
    &:active {
      background-color: #f5f5f5 !important;
      color: #000;
    }
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    padding: 0px;
    border-radius: 4px;
    .dropdown-item {
      font-size: 14px;
      color: #333;
      padding: 4px 8px;
      &:hover {
        background-color: #f5f5f5;
      }
      .ssd-item-icon {
        font-size: 17px;
        margin-top: -3px;
        color: #333;
        &:hover {
          color: #666;
        }
      }
      .ssd-item-title {
        margin-left: 4px;
        color: #333;
        &:hover {
          color: #666;
        }
      }
    }
    .dropdown-item.disabled {
      color: #999;
      .ssd-item-icon {
        color: #999;
      }
      .ssd-item-title {
        color: #999;
      }
      &:hover {
        color: #999;
      }
    }
    .dropdown-item.active {
      background-color: #ddd;
      &:hover {
        background-color: #ddd;
      }
    }
  }
}

small.beta {
  margin-left: 4px;
  margin-top: 0px;
  background-color: #2083a6;
  font-size: 9px;
  color: #fff;
  padding: 1px 5px;
  border-radius: 4px;
}

small.newtab {
  color: #666;
  font-size: 13px;
  margin-left: 3px;
  &:hover {
    background-color: #f5f5f5;
  }
}

.sub-dd-divider {
  margin: 1px 0px;
}
.left-aligned-modal {
  position: fixed;
  top: 0;
  left: 10px; /* 10px from the left side */
  width: auto; /* Adjust based on your needs */
  max-width: none; /* Allows you to extend beyond the default max-width if necessary */
}
.order-modal-dialog .modal-dialog {
  display: inline-flex !important;
  max-width: 100%; 
  margin:auto;
  position: fixed;
  left:30%;
  top:10%;
}

.order-modal-dialog .modal-content {
  display: inline-flex;
  overflow-x: auto;
}

.order-modal-dialog .modal-header,
.order-modal-dialog .modal-body {

}
