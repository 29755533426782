.row-title {
  background-color: #eee;
  padding: 10px 10px;
  margin-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
  .text {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .spinner {

  }
}
.ud-row {
  padding: 10px;
  border: 1px solid #eee;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  .ud-col {
    padding: 10px 0px;
    .key {
      font-size: 16px;
      font-weight: 500;
    }
    .val {
      font-size: 16px;
    }
    .val.urole {
        background-color: #5f9ea0;
        padding: 2px 10px;
        border-radius: 8px;
        color: #fff;
        display: inline-block;
    }
  }
}
