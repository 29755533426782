
  .generate-button {
    padding: 5px 10px;
    background-color: #1a6985;
    cursor: pointer;
    color: #fff;
    border-radius: 6px;
    .create-sub-btn-icon {
      font-size: 18px;
      color: #fff;
      padding-right: 3px;
      margin-top: -2px;
    }
  }
  .generate-button:hover {
    
  }
  
  /* .generate-button:disabled {
    background-color: gray;
    cursor: not-allowed;
  } */
  /* Define styles for each status */
.generate-button-initial {
  background-color: #1a6985;
}
.generate-button-inflight {
  background-color:  gray;
}
.generate-button-completed {
  background-color:  #1a6985;
}
.generate-button-failed {
  background-color: red;
}
.generate-button-fulfilled {
  background-color:  #1a6985;
}

.report-download-btn-completed {
  background-color: #5cad63 !important;
  border-radius: 20px;
  border-color: #582305 !important;
  color:black !important;
  font-weight: bold;
}

.report-download-btn-failed {
  background-color: #b56770 !important;
  border-radius: 20px;
  font-family:Arial, Helvetica, sans-serif
  
}

.report-download-btn-inflight {
  background-color: #ffbe98 !important;
  border-radius: 20px;
  border-color: #582305 !important;
  color:black !important;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.report-download-btn-no_data {
  background-color: #a29999 !important;
  border-radius: 20px;
  border-color: #030303 !important;
  color:rgb(0, 0, 0) !important;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.report-download-btn-not-available {
  background-color: #666 !important;
  border-radius: 20px;
  border-color: #582305 !important;
  color:black !important;
}

.expand-btn {
  cursor: pointer;
  border:none;
  color: #9dceff;
  margin-top: -4px;
  font-size: 22px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
}
.expand-btn:hover{
  background-color: transparent;
  
}

