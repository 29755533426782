.wf-btn-icon {
  font-size: 17px;
  margin-top: -2px;
}

.search-bar-wrap {
  margin: 10px 0px 10px 0px;
  .search-bar {
    padding-left: 0px;
    .text {
      input.form-control {
        border-radius: 50px 0 0 50px;
        font-weight: normal;
        padding-left: 25px;
        &:focus {
          border-color: #d1d6dc;
          box-shadow: 0 0 0 0.25rem rgb(220 224 229 / 50%);
        }
      }
      label {
        padding-left: 25px;
      }
    }
    .options {
      .dropdown {
        .dropdown-toggle {
          padding: 12px 15px 12px 15px;
          border-radius: 0px;
          line-height: 2rem;
          background-color: #6c757d;
          border-color: #65757d;
          border-right: 1px solid #aaa;
        }
      }
    }
    .search {
      .btn {
        border-radius: 0 50px 50px 0;
        padding: 12px 0px;
        span.i {
          padding: 0px 1.5rem;
          svg {
            font-size: 32px;
          }
        }
      }
    }
  }
}

.workflow-table {
  thead > tr {
    color: #666;
    border-bottom: 1px solid #ddd;
    th {
      font-weight: bold;
      font-size: 16px;
    }
  }
  tbody > tr {
    color: #000;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    &:active,
    &:focus {
      background-color: #eee;
    }
    td {
      font-size: 14px;
      padding: 10px 8px;
      .wf-status {
        padding: 2px 8px;
        text-align: center;
        font-weight: 400;
        color: #fff;
        border-radius: 8px;
        border: none;
      }
    }
  }
}
