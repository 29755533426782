.role {
  padding: 2px 10px;
  border-radius: 6px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}
.role.u {
  background-color: #0a8080;
}

.role.a {
  background-color: #991a1a;
}
